@use 'node_modules/@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use 'node_modules/@sparbanken-syd/sparbanken-syd-theme/variables' as var;
@import 'variables';

@include spb.set-typography();

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: sans-serif;
  height: 100%;
  margin: 0 auto;
  width: 100%;
}

/* General styling that applies to many pages */

/* Plain is just a holder for content on pages w/o hero images.*/
.spb-plain-content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  max-width: var.$spb-max-width;
  width: 100%;
}

.spb-error {
  background-color: var.$spb-red;
  color: $spb-white;
  font-weight: bold;
  margin: 1em auto;
  padding: 0.7em;
  text-align: center;
  width: 90%;
}


/*
Header in tables
*/
th {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex: 1 0;
}
